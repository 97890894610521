import { render, staticRenderFns } from "./BannerSales.vue?vue&type=template&id=98aabbdc"
import script from "./BannerSales.vue?vue&type=script&lang=ts"
export * from "./BannerSales.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default,ModalNewsletterTemporary: require('/home/runner/work/webapp/webapp/components/organisms/modal/ModalNewsletterTemporary.vue').default})
