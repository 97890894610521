
import Vue from 'vue';

import { _isCurrentDateBetweenTwoDates } from '@/utilities/dates/isCurrentDateBetweenTwoDates';

interface SalesBanner {
  availableOn: string;
  cta: string;
  date: {
    end: string;
    start: string;
  };
  imageId: {
    coloured: string;
    grayedOut: string;
  };
  imageSrc: string;
  isSelectionActive: boolean;
  linkToSelection: string | null;
  title: string;
}

export default Vue.extend({
  name: 'BannerSales',
  data() {
    return {
      isModalOpen: false,
    };
  },
  computed: {
    items(): SalesBanner[] {
      const items = this.$t('page.home.edito.salesBanner.items') as unknown as SalesBanner[];

      return items.map((item) => {
        const isSelectionActive = _isCurrentDateBetweenTwoDates({ start: item.date.start, end: item.date.end });
        const imageId = isSelectionActive ? item.imageId.coloured : item.imageId.grayedOut;

        return {
          ...item,
          imageSrc: require(`@/assets/images/${imageId}.png`),
          isSelectionActive,
          linkToSelection: this.$externalURLRouter.getPathFromRoute('selections', { slug: item.linkToSelection! }),
        };
      });
    },
  },
  methods: {
    handleClick(isSelectionActive: SalesBanner['isSelectionActive']): void {
      if (!isSelectionActive) {
        this.isModalOpen = true;
      }
    },
    closeModal(): void {
      this.isModalOpen = false;
    },
  },
});
